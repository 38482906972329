import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import { deleteEditDetailsScreen } from 'api/sections';
import React, { FC } from 'react';
import { useHistory } from 'react-router';

interface Props {
  open: boolean;
  onClose: () => void;
  id: number;
  name: string;
  inBlock: boolean;
}

const DeleteDetailDialog: FC<Props> = ({
  open,
  onClose,
  id,
  name,
  inBlock,
}) => {
  const history = useHistory();
  const handleClose = () => {
    onClose();
  };
  const handleDelete = () => {
    deleteEditDetailsScreen(id);
    onClose();
    if (inBlock) {
      const route = history.location.pathname.split('/');
      history.push(`${route.slice(0, route.length - 1).join('/')}`);
    }
    window.location.reload();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid container justifyContent="center">
        <Typography
          variant="body1"
          style={{ margin: '24px', textAlign: 'center' }}
        >
          Вы уверены, что хотите удалить &quot;{name}&quot;
        </Typography>
        <Box width="100%" display="flex" justifyContent="space-between" m={2}>
          <Button onClick={handleDelete} style={{ marginLeft: '24px' }}>
            <Typography variant="body1" color="error">
              Удалить
            </Typography>
          </Button>
          <Button onClick={handleClose} style={{ marginRight: '24px' }}>
            <Typography variant="body1">Отмена</Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default DeleteDetailDialog;
