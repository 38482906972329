import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  blockId: number;
  onClose: () => void;
  onConfirm: (id: number) => void;
}

export const RemoveBlockDialog: FC<Props> = ({
  onClose,
  onConfirm,
  open,
  blockId,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid container style={{ padding: '12px', justifyContent: 'center' }}>
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          Вы действительно хотите удалить этот блок?
        </Typography>
        <Box width="100%" display="flex" justifyContent="space-between" m={2}>
          <Button
            onClick={() => {
              onConfirm(blockId);
            }}
            style={{ marginLeft: '24px' }}
          >
            <Typography variant="body1" color="error">
              Удалить
            </Typography>
          </Button>
          <Button onClick={handleClose} style={{ marginRight: '24px' }}>
            <Typography variant="body1">Выйти</Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};
