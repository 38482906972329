import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import EmptyBlock from 'components/EmptyBlock/EmptyBlock';
import React, { FC, useState } from 'react';
import { SelectedBorder } from './style';

interface Props {
  open: boolean;
  onClose: () => void;
  startIds: number[];
  allBlocks: any[];
  onSave: (newIds: number[]) => void;
}

const AddStartBlockDialog: FC<Props> = ({
  open,
  onClose,
  startIds,
  allBlocks,
  onSave,
}) => {
  const [selectedBlocksIds, setSelectedBlocksIds] = useState<Array<number>>([]);
  const handleClose = () => {
    onClose();
  };

  const handleClick = (id: number) => {
    if (!selectedBlocksIds.includes(id)) {
      selectedBlocksIds.push(id);
    } else {
      selectedBlocksIds.splice(selectedBlocksIds.indexOf(id), 1);
    }
  };

  const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.style.border === '3px solid green') {
      event.currentTarget.style.border = '0px';
    } else {
      event.currentTarget.style.border = '3px solid green';
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid
        container
        justifyContent="center"
        style={{ padding: '16px', gap: '16px' }}
      >
        <Typography
          variant="h6"
          style={{ textAlign: 'center', marginBottom: '12px' }}
        >
          Выберите блоки, которые хотите добавить в стартовые
        </Typography>
        {allBlocks.map((item) => {
          if (startIds.includes(item.id)) {
            return null;
          } else {
            return (
              <SelectedBorder key={item.id} onClick={handleSelect}>
                <div style={{ position: 'relative' }}>
                  <EmptyBlock
                    describe={item.name || item.title}
                    imgSrc={item.media?.downloadUrl}
                    onClick={() => {
                      handleClick(item.id);
                    }}
                  />
                </div>
              </SelectedBorder>
            );
          }
        })}
        <Box width="100%" display="flex" justifyContent="space-between" m={2}>
          <Button
            onClick={() => {
              onSave(selectedBlocksIds);
            }}
            style={{ marginLeft: '24px' }}
          >
            <Typography variant="body1" color="primary">
              Сохранить
            </Typography>
          </Button>
          <Button onClick={handleClose} style={{ marginRight: '24px' }}>
            <Typography variant="body1">Выйти</Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default AddStartBlockDialog;
